<template>
  <section
    class="call-to-action"
    :class="colorClass"
  >
    <UiHeader
      v-if="header"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
      tag="div"
    />

    <T3HtmlParser
      v-if="bodytext"
      class="call-to-action__text"
      :content="bodytext"
    />

    <div class="call-to-action__btn">
      <ButtonBase
        :google-tag="googleTag"
        :button="customButtonProps"
        @click="onClick"
      />
    </div>

    <Calendar
      :is-open="isCalendarOpen"
      :calendar-id="calendarId"
      @close="handleCalendarClose"
    />
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { ButtonBase, UiHeader } from '~ui/components'
import Calendar from '~ui/components/Base/Calendar/Calendar.vue'
import { getValidLink } from '~ui/helpers/linkUtils'
import { isScrollToLink, scrollToElement } from '~ui/helpers/scrollToElement'
import type { UiCeTextProps } from '~ui/types'

const props = defineProps<UiCeTextProps>()

const buttonLink = ref(getValidLink(props.button.link))

const isScrollToButton = computed(() => {
  return buttonLink.value && isScrollToLink(buttonLink.value)
})

const calendarId = computed(() => {
  // eslint-disable-next-line @stylistic/no-extra-parens
  return (props.checkboxCalendar && (props.button?.link as string)) || null
})

const customButtonProps = computed(() => {
  return isScrollToButton.value || calendarId.value
    ? { ...props.button, link: '' }
    : props.button
})

const colorClass = computed(() => {
  return props.checkboxColor ? 'call-to-action--white-font' : ''
})

const isCalendarOpen = ref(false)
const handleCalendarOpen = () => isCalendarOpen.value = true
const handleCalendarClose = () => isCalendarOpen.value = false

const emit = defineEmits<{
  (event: 'gtm-event', tag: string): void
}>()

const onClick = () => {
  if (props.googleTag) {
    emit('gtm-event', props.googleTag)
  }

  if (props.checkboxCalendar) {
    handleCalendarOpen()
  } else if (buttonLink.value && isScrollToButton.value) {
    scrollToElement(buttonLink.value)
  }
}
</script>

<style lang="scss">
.call-to-action {
  position: relative;
  padding: em(45px) em(20px) em(65px);
  color: color(black);

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    translate: -50%;
    width: rem(5px);
    height: rem(56px);
    background-color: color(black);
  }

  &--white-font {
    color: color(white);

    &::after {
      background-color: color(white);
    }
  }

  &__text {
    width: 100%;
  }

  &__btn {
    padding-bottom: em(35px);
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
</style>
